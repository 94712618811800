<template>
  <v-app>
    <tev-navbar backTo="/" title="FCRA"/>
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12 class="mb-5">
                <fcra v-on:close="doClose" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import fcra from '../components/fcra'
export default {
  components: {
    fcra
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    doClose() {
      this.$router.push('/')
    }
  }
}
</script>
