var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "headline mb-3 black--text" }, [
        _vm._v("Fair Credit Reporting Act (FCRA)"),
      ]),
      _c("p", { staticClass: "mb-3" }, [_vm._v("You shall:")]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "a. Comply with all applicable federal, state and local laws governing the access and use of Screening Reports. This includes, without limitation, providing any Applicant with any consents, disclosures and reports required by the FCRA, and obtaining the prior written consent of the Applicant before procuring a Screening Report on the Applicant."
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          'b. Comply with FCRA Section 606(a)(2) and applicable state and local laws each time You request an "Investigative Consumer Report" (as defined by the FCRA and/or state and local laws) from Tenant Evaluation LLC for any Applicant.'
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "c. Assume full responsibility for the final verification of the Applicant's identity;"
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "d. Base all Your Tenant or hiring decisions and related actions on Your policies and procedures and not rely on Tenant Evaluation LLC for (nor shall Tenant Evaluation LLC render) any opinions on or for any Screening Reports;Seek legal advice from and utilize qualified legal counsel for the use of any Screening Reports provided under this Agreement."
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "e. Keep strictly confidential in accordance with this Agreement any information and identification numbers and passwords You receive from or gain access through Tenant Evaluation LLC."
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "f. For six years from the date You receive the Screening Report, keep a copy or copies of: (i) the Screening Report, (ii) the consent form You obtained from the Applicant for the applicable Screening Report, and (iii) a description of how You used or relied on the Screening Report in making a decision about Applicant."
        ),
      ]),
      _c("p", { staticClass: "mb-5" }, [
        _vm._v(
          "g. LIMITED WARRANTY AND REMEDY. Tenant Evaluation LLC warrants only that it will provide Screening Reports based on Tenant Evaluation LLC' then-current access to public databases. If Tenant Evaluation LLC materially breaches this warranty, it may, at its sole discretion, re-perform the applicable search or refund Your fees for that search. You understand and agree that this is Your sole and exclusive remedy for any material breach of this warranty."
        ),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "mb-3",
          attrs: {
            color: "primary",
            round: "",
            large: "",
            block: "",
            depressed: "",
            href: "https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/fair-credit-reporting-act",
            target: "_blank",
          },
        },
        [
          _vm._v("\n    Read the full FRCA "),
          _c("v-icon", { staticClass: "ml-3" }, [_vm._v("open_in_new")]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mb-5",
          attrs: {
            color: "primary",
            round: "",
            large: "",
            block: "",
            depressed: "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("\n    Close\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }